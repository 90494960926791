import idx from "idx";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import qs from "query-string";
import * as React from "react";
import Swiper from "react-id-swiper";
import { connect } from "react-redux";
import { ViewsByTypes } from "src/api";
import Banner from "src/components/Banner";
import NavigationDots from "src/components/NavigationDots";
import Page from "src/components/Page";
import Tabs from "src/components/Tabs";
import constants from "src/helpers/constants";
import history from "src/helpers/history";
import urls from "src/helpers/urls";
import useDeepCompareEffect from "src/helpers/useDeepCompareEffect";
import utility from "src/helpers/utility";
import * as AppActions from "src/store/actions/app";
import * as ExhibitActions from "src/store/actions/exhibit";
import { IReduxState } from "src/store/reducers";
import { ExhibitDataType, LocationType } from "src/store/types";
import { AreaType } from "src/store/types/areas";
import Content from "./Content";
import RelatedArticles from "./RelatedArticles";

import {
  SwipePageContentWrapper,
  TabContainer,
  TabPageListWrapper,
} from "./styled";
import FAB from "src/components/FAB";

type Props = IScreenProps & {
  activeLanguage: string;
  exhibitId?: string;
  getExhibit?: Function;
  removeTemporaryTopicsFromSecretExhibits?: Function;
  locationId?: string;
  locations?: {
    [locationId: string]: {
      data: LocationType;
    };
  };
  exhibitData: ExhibitDataType;
  setCurrentExhibitId?: Function;
  currentExhibitViewsBy: string;
};

const Exhibit: React.FC<Props> = props => {
  const {
    activeLanguage,
    getExhibit,
    exhibitId,
    locationId,
    locations,
    location,
    exhibitData,
    currentExhibitViewsBy,
  } = props;

  const sliderRef = React.useRef(null);
  const allExhibitsRef = React.useRef([]);
  const isSliderMoveRef = React.useRef(false);
  const [currentLocationId, setCurrentLocationId] = React.useState(
    locationId || ""
  );
  const areas =
    idx(locations, x => x[currentLocationId].data.areas) || ([] as AreaType[]);

  const defaultLanguage =
    idx(locations, x => x[currentLocationId].data.defaultLanguage) || "";

  let allExhibits = [] as Array<{
    id: string;
    areaId: string;
    areaIndex: number;
  }>;
  for (let index = 0; index < areas.length; index++) {
    const area = areas[index];
    const exhibits = idx(area, x => x.exhibits) || [];
    const tabs = exhibits.map(exhibit => ({
      id: idx(exhibit, x => x._id.$oid),
      areaId: idx(area, x => x._id.$oid),
      areaIndex: index,
    }));
    allExhibits = [...allExhibits, ...tabs];
  }
  allExhibitsRef.current = allExhibits;

  let exhibitSlideIndex = findIndex(allExhibits, { id: exhibitId });
  exhibitSlideIndex = exhibitSlideIndex <= -1 ? 0 : exhibitSlideIndex;
  const exhibitSlide = find(allExhibits, { id: exhibitId });
  const areaIndex = idx(exhibitSlide, x => x.areaIndex) || 0;
  const [currentAreaIndex, setCurrentAreaTabIndex] = React.useState(areaIndex);
  const [currentExhibitIndex, setCurrentExhibitIndex] = React.useState(
    exhibitSlideIndex
  );

  // set current location id
  React.useEffect(() => {
    if (locationId) {
      setCurrentLocationId(locationId);
    }
  }, [locationId]);

  // load exhibit
  useDeepCompareEffect(() => {
    const parsedQuery = qs.parse(location.search);
    props.setCurrentExhibitId(
      exhibitId,
      parsedQuery.feature || currentExhibitViewsBy // if we have feature set in url then use it , or else use it from redux. In case user is swiping mostly feature will be set in redux and we will use it here
    );
    const feat = parsedQuery.feature;
    const reqLocationId = feat !== "scan" ? locationId : undefined;
    if (exhibitId) {
      getExhibit(exhibitId, false, reqLocationId);
    }
  }, [exhibitId]);

  // update branch banner data
  React.useEffect(() => {
    const linkData = {
      data: {
        exhibit_id: exhibitId,
      },
    };

    // close journey and open it again with new data, so that mobile will get proper data
    // if branch banner data is not set then
    if (!window.isAppBannerDataSet) {
      setTimeout(() => {
        window.branch.closeJourney();
        setTimeout(() => {
          window.branch.setBranchViewData(linkData);
          window.branch.track("pageview");
          // this is used to check in other page whether to update branch banner data or not
          window.isAppBannerDataSet = true;
        }, 1000);
      }, 1000);
    }
  }, []); // eslint-disable-line

  React.useEffect(() => {
    // if exhibit slide index or area index changes then update states
    if (currentAreaIndex !== areaIndex) {
      setCurrentAreaTabIndex(areaIndex);
    }
    if (currentExhibitIndex !== exhibitSlideIndex) {
      setCurrentExhibitIndex(exhibitSlideIndex);
    }
    // Scroll to the top
    window.scrollTo(0, 0);
  }, [exhibitSlideIndex, areaIndex]); // eslint-disable-line

  const updateAutoHeight = () => {
    if (sliderRef && sliderRef.current) {
      // update autoheight of slide
      sliderRef.current.updateAutoHeight();
    }
  };

  const onChangeExhibit = (navigateExhibitId, exhibitIndex, newAreaIndex) => {
    setCurrentExhibitIndex(exhibitIndex);
    setCurrentAreaTabIndex(newAreaIndex);
    // remove temporary exhibits on swipe
    props.removeTemporaryTopicsFromSecretExhibits();
    // here we are pushing url so user can have back button functionality in browser
    history.push(urls.exhibit(navigateExhibitId, ViewsByTypes.menu));
  };

  const params = {
    activeSlideKey: "" + currentExhibitIndex,
    shouldSwiperUpdate: true,
    autoHeight: true,
    noSwiping: window.screen.width > 959,
    on: {
      sliderMove: () => {
        // track that user is moving slider by hand
        isSliderMoveRef.current = true;
      },
      slideChange: () => {
        if (sliderRef.current && isSliderMoveRef.current) {
          // if user moved slider then only run this logic
          // if slide changes automatically by changing url then dont run this logic
          isSliderMoveRef.current = false;
          const activeIndex = sliderRef.current.activeIndex;
          const tabObj = allExhibitsRef.current[activeIndex];
          if (tabObj) {
            onChangeExhibit(tabObj.id, activeIndex, tabObj.areaIndex);
          }
        }
      },
    },
  };

  const exhibitImage = exhibitData?.exhibitImageThumb;
  const exhibitQRPath = exhibitData?.qrPath;
  let orgName = locations[locationId]?.data?.companyName;

  const pageTitle = utility.getName(
    exhibitData.name,
    activeLanguage,
    defaultLanguage
  );
  const currentAreaExibits = idx(areas, x => x[areaIndex].exhibits) || [];
  const exhibitIndexInArea = findIndex(currentAreaExibits, {
    _id: { $oid: exhibitId },
  });

  const getTabItems = () => {
    const tabItems = areas.map((area, index) => {
      const areaName = utility.getName(
        area.name,
        activeLanguage,
        defaultLanguage
      );
      const areaId = idx(area, x => x._id.$oid);
      return {
        label: areaName,
        key: areaId,
        pagesCount: area?.exhibitOrder?.length,
        index,
        exhibitsLength: area.exhibits.length,
      };
    });
    return tabItems;
  };

  return (
    <Page>
      <Banner
        locationId={currentLocationId}
        image={exhibitImage}
        useImageFromLocation={false}
      />
      <span>
        {areas.length > 0 && (
          <TabContainer>
            <Tabs
              activeLanguage={activeLanguage}
              exhibitId={idx(allExhibits, x => x[currentExhibitIndex || 0].id)}
              locationId={currentLocationId}
              index={currentAreaIndex}
              items={getTabItems()}
              onTabClick={tab => {
                const area = idx(areas, x => x[tab.index]);
                const firstExhibitid = idx(area, x => x.exhibits[0]._id.$oid);
                const exhibitIndex = findIndex(allExhibits, {
                  id: firstExhibitid,
                });
                onChangeExhibit(firstExhibitid, exhibitIndex, tab.index);
                props.removeTemporaryTopicsFromSecretExhibits();
              }}
            />
          </TabContainer>
        )}

        <NavigationDots
          total={currentAreaExibits.length}
          current={exhibitIndexInArea}
        />
        <SwipePageContentWrapper
          onLoad={() => {
            if (sliderRef && sliderRef.current) {
              sliderRef.current.updateAutoHeight();
            }
          }}
        >
          <Swiper
            {...params}
            getSwiper={ref => {
              sliderRef.current = ref;
            }}
          >
            {allExhibits.map((exhibit, index) => {
              return (
                <div key={index} id={"" + index}>
                  <Content
                    activeLanguage={activeLanguage}
                    defaultLanguage={defaultLanguage}
                    exhibitId={exhibit.id}
                    isVisible={index === currentExhibitIndex}
                    updateAutoHeight={updateAutoHeight}
                    locationId={currentLocationId}
                  />
                </div>
              );
            })}
          </Swiper>

          <TabPageListWrapper>
            <RelatedArticles
              activeLanguage={activeLanguage}
              exhibitId={idx(allExhibits, x => x[currentExhibitIndex || 0].id)}
              locationId={currentLocationId}
            />
          </TabPageListWrapper>
        </SwipePageContentWrapper>
        <FAB
          organizationName={orgName}
          pageName={pageTitle}
          qrImage={"https://storage.googleapis.com/liii/" + exhibitQRPath}
        />
      </span>
    </Page>
  );
};

export default connect<Props>(
  (state: IReduxState, ownProps: Props) => {
    const idFromUrl = idx(ownProps.match as any, x => x.params.id);
    const id = idx(state, x => x.app.currentExhibitId);
    const exhibitDetails = idx(state, x => x.topics.exhibitDetails[id]);
    const exhibit = idx(exhibitDetails, x => x.data) || ({} as ExhibitDataType);
    const locationId = idx(state, x => x.app.currentMenuId) || "";
    const locations = idx(state, x => x.location.locations);
    const activeLanguage = idx(state, x => x.language.activeLanguage);

    let exhibitId = "";

    if (idFromUrl) {
      // if we have topic id in url , then take it
      exhibitId = idFromUrl;
    } else if (id) {
      // if we have topic id set in redux , then take it
      exhibitId = id;
    } else {
      // else take default id
      exhibitId = constants.DEFAULT_TOPIC_ID();
    }

    return {
      activeLanguage,
      exhibitId,
      exhibitData: exhibit,
      locationId,
      locations,
      currentExhibitViewsBy: idx(state, x => x.app.currentExhibitViewsBy),
    };
  },
  {
    removeTemporaryTopicsFromSecretExhibits:
      ExhibitActions.removeTemporaryTopicsFromSecretExhibits,
    getExhibit: ExhibitActions.getExhibit,
    setCurrentExhibitId: AppActions.setCurrentExhibitId,
  }
)(Exhibit);
